<template>
  <div>
    <Header :customstyle="{ background: '#0C1913' }">
      <template slot="title" v-if="coin_symbol=='POINT'">CNY记录</template>
      <template slot="title" v-else>{{ coin_symbol }}记录</template>
    </Header>
    <div class="content">
      <van-tabs
        class="tab"
        v-model="activeTab"
        @change="changeTab"
        title-active-color="#27CC7F"
        title-inactive-color="#8BA695"
        background="#0C1913"
        color="#27CC7F"
      >
        <van-tab title="转入"></van-tab>
        <van-tab title="转出"></van-tab>
        <van-tab title="其他"></van-tab>
      </van-tabs>
      <div class="card">
        <div class="datalist">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多记录了"
              @load="onLoad"
            >
              <div
                class="cell"
                v-for="(record, index) in recordList"
                :key="index"
                v-if="record.type!=55&&record.type!=74&&record.type!=78"
              >
                <!-- <div class="date">{{record.time}}</div> -->
                <div class="detail">
                  <div class="topnum">
                    <div class="num">
                      {{ parseFloat(record.amount).toFixed(3) }}
                    </div>
                    <div class="txt" v-if="coin_symbol=='POINT'">数量(CNY )</div>
                    <div class="txt" v-else>数量({{ coin_symbol }})</div>
                  </div>
                  <div class="topnum">
                    <div class="time">
                      {{ getMemoTxt(record.type) }}
                    </div>
                    <div class="time">
                      <!--.substring(0,11)-->
                      时间:{{ record.timestamp }}
                    </div>
                  </div>
                  <div class="status">
                    {{ getTransferTxt(record.transfer_status) }}
                  </div>
                </div>
              </div>
            </van-list>
          </van-pull-refresh>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/header.vue";
import { transferLog } from "@/request/api";
import { Toast } from "vant";
export default {
  components: {
    Header,
  },
  computed: {
    getTransferTxt() {
      return (val) => {
        let txt = "";
        switch (val) {
          case -1:
            txt = "失败(拒绝)";
            break;
          case 0:
            txt = "等待处理";
            break;
          case 1:
            txt = "成功";
            break;
          case 2:
            txt = "转账中";
            break;
        }
        return txt;
      };
    },
    getMemoTxt() {
      return (val) => {
        let txt = "";
        switch (val) {
          case 1:
            txt = "转入";
            break;
          case 2:
            txt = "转出";
            break;
          case 3:
            txt = "推广获得";
            break;
          case 4:
            txt = "推荐奖励";
            break;
          case 5:
            txt = "环保收益";
            break;
          case 6:
            txt = "转换器获得";
            break;
          case 7:
            txt = "转换器扣除";
            break;
          case 8:
            txt = "转换器返回";
            break;
          case 9:
            txt = "环保扣除";
            break;
          case 10:
            txt = "环保返回";
            break;
          case 11:
            txt = "交易扣除";
            break;
          case 12:
            txt = "交易获得";
            break;
          case 13:
            txt = "活跃获得";
            break;
          case 14:
            txt = "活跃减少";
            break;
          case 15:
            txt = "共识度增加";
            break;
          case 16:
            txt = "手续费消耗";
            break;
          case 17:
            txt = "交易出售冻结";
            break;
          case 18:
            txt = "交易冻结扣除";
            break;
          case 19:
            txt = "兑换映射";
            break;
          case 20:
            txt = "系统调整";
            break;
          case 21:
            txt = "交易购买获得";
            break;
          case 22:
            txt = "交易退回";
            break;
          case 23:
            txt = "交易手续费获得";
            break;
          case 24:
            txt = "全球交易手续费加权";
            break;
            case 25:
                txt = "交易销毁冻结";
                break;
            case 26:
                txt = "交易销毁";
                break;
            case 31:
                txt = "促销打赏";
                break;
            case 32:
                txt = "购买商品";
                break;
            case 33:
                txt = "申请商家冻结";
                break;
            case 34:
                txt = "出售商品";
                break;
            case 35:
                txt = "商家通过扣除";
                break;
            case 36:
                txt = "商家退回";
                break;
            case 41:
                txt = "质押商家";
                break;
            case 42:
                txt = "退出商家";
                break;
            case 51:
                txt = "游戏红包";
                break;
            case 52:
                txt = "LP持有算力分红";
                break;
            case 53:
                txt = "LP推荐算力分红";
                break;
            case 54:
                txt = "消费待返";
                break;
            case 55:
                txt = "待返释放扣除";
                break;
            case 56:
                txt = "商家让利";
                break;
            case 57:
                txt = "已返获得";
                break;
            case 71:
                txt = "签到获得";
                break;
            case 72:
                txt = "订单获得";
                break;
            case 73:
                txt = "分享获得";
                break;
            case 74:
                txt = "销毁转全返";
                break;
            case 75:
                txt = "新人首单";
                break;
            case 76:
                txt = "直推获得";
                break;
            case 77:
                txt = "推广获得";
                break;
            case 78:
                txt = "待返ATT";
                break;
            case 79:
                txt = "积分映射";
                break;
            case 80:
                txt = "全返生态";
                break;
            case 81:
                txt = "平移扣除";
                break;
            case 82:
                txt = "平移获得";
                break;
            case 90:
                txt = "投票扣除";
                break;
          default:
            txt = "其它";
            break;
        }
        return txt;
      };
    },
  },
  data() {
    return {
      activeTab: 0,
      refreshing: false,
      loading: false,
      finished: false,
      recordList: [],
      coin_symbol: "",
      address: sessionStorage.getItem("address"),
      currentPage: 1,
    };
  },
  activated() {
    this.coin_symbol = this.$route.query.coin;
    this.refreshing = true;
    this.activeTab = 0;
  },
  methods: {
    changeTab(name, title) {
      this.refreshing = true;
      this.onRefresh();
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.currentPage = 1;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    onLoad() {
      if (this.address) {
        this.getDataList();
          this.currentPage++;
      } else {
        this.loading = false;
      }

    },
    getDataList() {
      const params = {
        address: this.address,
        type: this.activeTab + 1,
        coin_symbol: this.coin_symbol,
        limit: "20",
        page: this.currentPage,
      };
      transferLog(params).then((res) => {
        if (this.refreshing) {
          this.recordList = [];
          this.refreshing = false;
        }
        this.loading = false;
        this.recordList = [...this.recordList, ...res.data.list];
        if (res.data.list.length < 20) {
          this.finished = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 10px 32px;
  background: #0c1913;
  min-height: 100vh;
  .card {
    width: 686px;
    border-radius: 24px;
    opacity: 1;
    background: rgba(32, 40, 35, 1);
    .datalist {
      padding: 24px;
      .date {
        margin-top: 32px;
        color: rgba(139, 166, 149, 1);
        font-weight: 400;
        text-align: left;
        line-height: 52px;
        font-size: 40px;
      }
      .detail {
        display: flex;
        justify-content: space-between;
        .topnum {
          margin-top: 32px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          .num {
            color: rgba(214, 255, 227, 1);
            font-size: 35px;
            font-weight: 500;
            font-family: "DIN";
          }
          .txt {
            color: rgba(139, 166, 149, 1);
            font-size: 24px;
            font-weight: 400;
            font-family: "PingFang SC";
            text-align: center;
            line-height: 36px;
          }
          .time {
            color: rgba(139, 166, 149, 1);
            font-size: 28px;
            font-weight: 400;
            font-family: "PingFang SC";
          }
        }
        .status {
          color: rgba(39, 204, 127, 1);
          font-size: 20px;
          font-weight: 400;
          font-family: "PingFang SC";
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
</style>